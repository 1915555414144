import type { RouteRecordRaw } from 'vue-router';

import DashboardLayout from '@/layouts/dashboard.vue';

import account from './account';
import api from './apis';
import { dashboardGuard } from './dashboard-guard';
import iaas from './iaas';
import createRegistry from './iaas/container-registry/create';
import registry from './iaas/container-registry/registry';
import databases from './iaas/databases';
import createDatabase from './iaas/databases/create';
import database from './iaas/databases/database';
import createK8s from './iaas/kubernetes/create';
import k8s from './iaas/kubernetes/k8s';
import images from './images';
import networking from './networking';
import domains from './networking/domains';
import firewalls from './networking/firewalls';
import loadBalancer from './networking/loadbalancers';
import createLoadBalancer from './networking/loadbalancers/create';
import projects from './projects';
import createProject from './projects/create';
import project from './projects/project';
import security from './security';
import stacklets from './stacklets';
import createStacklet from './stacklets/create';
import stacklet from './stacklets/stacklet';
import storages from './storages';
import bucket from './storages/buckets/bucket';
import createBucket from './storages/buckets/create';
import tag from './tag';
import teams from './teams';
import team from './teams/team';
import userscripts from './userscripts';

export default {
  meta: { profile: true },
  path: '',
  component: DashboardLayout,
  name: 'dashboard',
  beforeEnter: dashboardGuard,
  children: [
    api,
    createProject,
    projects,
    project,
    createStacklet,
    stacklets,
    stacklet,
    images,
    networking,
    firewalls,
    domains,
    registry,
    createRegistry,
    createLoadBalancer,
    loadBalancer,
    createDatabase,
    databases,
    database,
    createK8s,
    k8s,
    iaas,
    storages,
    createBucket,
    bucket,
    tag,
    account,
    team,
    teams,
    security,
    userscripts,
  ],
} satisfies RouteRecordRaw;
